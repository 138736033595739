import React from "react";

const Photo = (props) => {
    return (
        <section>
            <img src={props.url} alt={props.name} weight="500" height="300" />
        </section>
    )
}

export default Photo