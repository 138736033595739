import React from "react";
import { SocialIcon } from 'react-social-icons';

const FooterContainer = () => {
    return (
        <footer>
        <h3>Payungsak Klinchampa (PaO) <br />
            Cloud Architect / Blogger / Researcher <br />
            Founder @ <a href="https://www.paocloud.co.th">PAOCLOUD COMPANY LIMITED</a> <br />
            <a href="mailto:pao@paocloud.co.th">pao[at]paocloud.co.th</a>, <a href="tel:+66937738265">+66-937738265</a> <br />
        </h3>
        <div className="App-social-icon">
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="http://facebook.com/paov6" fgColor="#f0f0f0"/>
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="https://twitter.com/payungsakpk" fgColor="#f0f0f0"/>
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="https://www.linkedin.com/in/paov6/" fgColor="#f0f0f0"/>
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="https://medium.com/payungsakpk" bgColor="#f0f0f0"/>
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="https://github.com/PayungsakCNR" fgColor="#f0f0f0"/>
          <SocialIcon className="App-social-icon-i" style={{ height: 25, width: 25 }} url="mailto:pao@payungsakpk.xyz" network="email" fgColor="#f0f0f0"/>
        </div>
        <br /><a href="https://aws.amazon.com/what-is-cloud-computing"><img width="100" src="https://d0.awsstatic.com/logos/powered-by-aws-white.png" alt="Powered by AWS Cloud Computing"></img></a>
        <br />
        </footer>
    )
}

export default FooterContainer
