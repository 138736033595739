import React from "react";
import PhotoContainer from "./PhotoContainer";
import FooterContainer from "./FooterContainer";
import "./App.css";

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isLoading: true,
      certs: []
    }
  }
  
  sortAscending = () => {
    const { certs } = this.state;
    certs.sort((a, b) => a.Id.N - b.Id.N)    
    this.setState({ certs })
  }
  async componentDidMount () {
    try {
      const res = await fetch("https://api-aws.payungsakpk.xyz/getcert-new")
      const allData = await res.json()
      this.setState({ isLoading: false,certs: allData });
      this.sortAscending()
    }
    catch (err) {
      console.log(err);
    }
  }
  
  render() {
    const { isLoading } = this.state;
    return (
      <div className="App">
        <header className="App-header">
        <h1>Pao's Certificate Wall.</h1>
        {isLoading ?
          <p>Loading ...</p>
          : 
            <PhotoContainer  certs={this.state.certs} />
        }
        <br />
        {isLoading ?
            <div></div>
          :  
            <FooterContainer />
        }
        </header>
      </div>
    );
  }
}

export default App;