import React from "react";
import Photo from "./Photo"



const PhotoContainer = props => {
    const displayPhotos = () => {
        return props.certs.map((cert) => {
            const id = cert.Id.N;
            return (
                <div key={id.toString()}>
                    <h2>{cert.Name.S}</h2>
                    <Photo url={cert.Url.S} />
                </div>
            )
        });
    };
    
    return (
        <>
            <section>{displayPhotos()}</section>
        </>
    )
}

export default PhotoContainer;